<template>
  <AuthFormWrapper>
    <template #formTitle>
      Reset your password
    </template>
    <template #formSubtitle>
      Enter a new password below.
    </template>

    <form
      @submit="onSubmit"
      class="w-full mt-12 lg:mt-16"
    >
      <BaseErrorMessage 
        v-if="submissionError"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        {{ submissionError }}
      </BaseErrorMessage>
      <BaseSuccessMessage 
        v-if="successMessage"
        extend-wrapper-classes="-mt-6 mb-4"
      >
        {{ successMessage }}
      </BaseSuccessMessage>
      
      <fieldset :disabled="working">
        <!-- Fields -->
        <VTextInput 
          name="email"
          type="email"
          label="Email Address"
          :value="email"
          readonly
        />
        <VTextInput 
          name="password"
          type="password"
          label="Password"
          maxlength="50"
        />
        <VTextInput 
          name="password_confirmation"
          type="password"
          label="Confirm Password"
          maxlength="50"
        />
      </fieldset>

      <!-- Submit -->
      <BaseButton
        theme="auth"
        size="full"
        type="submit"
        :working="working"
      >
        Reset
      </BaseButton>

      <!-- Additional Options -->
      <div class="flex justify-center text-gray-400 mt-8 text-sm xl:text-base">
        <RouterLink
          :to="{ name: 'login' }"
          class="hover:underline"
        >
          Back to login
        </RouterLink>
      </div>
    </form>
  </AuthFormWrapper>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import AuthApi from '@/apis/rota-architect/auth'
import { useRoute } from 'vue-router'
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { Resetable, ResetQuery } from '@/types/auth'

export default defineComponent({
  setup () {
    const working = ref(false)
    const submissionError = ref('')
    const successMessage = ref('')

    // extract token and user email from the route query
    const $route = useRoute()
    const { email, token } = $route.query as ResetQuery

    // init the reset form
    const resetSchema: yup.ObjectSchema<Resetable> = yup.object({
        email: yup
          .string()
          .email('Please provide a valid email.')
          .required('This field is required.'),
        password: yup
          .string()
          .max(25)
          .required('This field is required.'),
        password_confirmation: yup
          .string()
          .max(25)
          .oneOf([yup.ref('password'), ''], 'Password does not match.')
          .required('This field is required.'),
        token: yup.string(),
      }).defined()

    const { handleSubmit } = useForm({
      validationSchema:  resetSchema,
    })
    
    // handle reset
    const onSubmit = handleSubmit((values, actions) => {
      submissionError.value = ''
      successMessage.value = ''
      working.value = true

      // add token to payload.
      values.token = token

      AuthApi.resetPassword(values)
        .then((res) => {
          successMessage.value = res.data.message
          actions.resetForm()
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data) || 'Bad request'
        })
        .finally(() => {
          working.value = false
        })
    });


    return {
      email,
      working,
      onSubmit,
      submissionError,
      successMessage,
    };
  },

  // check that visiting page with required query params.
  beforeRouteEnter(to, _, next) {
      const { email, token } = to.query
      
			if (!email || !token) {
        next({ name: 'login' })
        return
      }  

      next()
		},
})
</script>